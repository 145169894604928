import axios from 'axios';
import router from './router/index';

const localhost = true;
export const URL =
    process.env.NODE_ENV === 'development'
        ? localhost
            ? 'http://localhost:3000'
            : 'http://h2944997.stratoserver.net:8080/api/'
        : '/api/';

const instance = axios.create({
    baseURL: URL,
    headers: {
        'Content-Type': 'application/json'
    }
});

instance.interceptors.response.use(
    res => res,
    error => {
        console.error(error);
        if (error && error.response && error.response.status) {
            console.error(JSON.stringify(error.response.status));
            if (+error.response.status === 403) {
                router.replace('/login');
                return;
            }
        }
        throw error;
    }
);

export default instance;
