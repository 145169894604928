<template>
    <div id="app">
        <navbar v-if="token && !isShare" @logout="login" />
        <v-app>
            <v-main>
                <transition>
                    <router-view @login="login" @isShare="enterShareMode()"></router-view>
                </transition>
            </v-main>
        </v-app>
    </div>
</template>

<script lang="ts">
import Navbar from './components/Navigatie/Navbar.vue';

export default {
    data() {
        return {
            token: localStorage.getItem('token'),
            admin: localStorage.getItem('admin'),
            isShare: false
        };
    },
    methods: {
        login() {
            this.token = localStorage.getItem('token');
            this.admin = localStorage.getItem('admin');
        },
        enterShareMode() {
            this.isShare = true;
        }
    },
    components: {
        Navbar
    },
    metaInfo: {
        titleTemplate: '%s | Taxatietool J-tax automotive'
    }
};
</script>
