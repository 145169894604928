export default [
    {
        path: '/admin',
        component: () => import('../components/Admin/AdminLijst.vue')
    },
    {
        path: '/admin/maak',
        component: () => import('../components/Admin/AdminFormulier.vue')
    },
    {
        path: '/admin/maak/:id',
        props: true,
        component: () => import('../components/Admin/AdminFormulier.vue')
    }
];
