import VueRouter from 'vue-router';
import axios from '../axios';
import adminRoutes from './admin';
import klantenRoutes from './klanten';
import taxatieRoutes from './taxaties';

const routes = [
    {
        path: '/',
        redirect: '/taxaties/lijst'
    },
    ...taxatieRoutes,
    ...klantenRoutes,
    ...adminRoutes,
    {
        path: '/login',
        component: () => import('../components/Admin/Login.vue')
    },
    {
        path: '*',
        redirect: '/taxaties/lijst'
    }
];

const router = new VueRouter({ routes });

router.beforeEach((to, from, next) => {
    if (to.path.includes('/taxaties/gedeeld/')) {
        next();
    } else if (!localStorage.getItem('token') && to.path !== '/login') {
        next('/login');
    } else {
        if (!axios.defaults.headers.authorization) {
            axios.defaults.headers.authorization = localStorage.getItem('token');
        }
        next();
    }
});

export default router;
