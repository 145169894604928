export default [
    {
        path: '/taxaties/lijst',
        component: () => import('../components/Taxatie/TaxatiesLijst/TaxatiesLijst.vue')
    },
    {
        path: '/taxaties/maak',
        component: () => import('../components/Taxatie/TaxatieFormulier.vue')
    },
    {
        path: '/taxaties/maak/nieuw',
        component: () => import('../components/Taxatie/TaxatieFormulierNieuw.vue')
    },
    {
        path: '/taxaties/maak/:id',
        props: true,
        component: () => import('../components/Taxatie/TaxatieFormulier.vue')
    },
    {
        path: '/taxaties/multiple',
        props: true,
        component: () => import('../components/Taxatie/TaxatiePreview.vue')
    },
    {
        path: '/taxaties/:id',
        props: true,
        component: () => import('../components/Taxatie/TaxatiePreview.vue')
    },
    {
        path: '/taxaties/gedeeld/:id/:access_token',
        component: () => import('../components/Taxatie/TaxatiePreview.vue')
    },
    {
        path: '/taxaties/gedeeld/:id/:access_token/:rapport_naam',
        component: () => import('../components/Taxatie/TaxatiePreview.vue')
    },
    {
        path: '/statistieken',
        component: () => import('../components/Statistieken/Statistieken.vue')
    }
];
