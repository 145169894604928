export default [
    {
        path: '/klanten',
        component: () => import('../components/Klanten/KlantenLijst.vue')
    },
    {
        path: '/klanten/maak',
        component: () => import('../components/Klanten/KlantFormulier.vue')
    },
    {
        path: '/klanten/maak/:id',
        props: true,
        component: () => import('../components/Klanten/KlantFormulier.vue')
    }
];
