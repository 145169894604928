<template>
    <div class="hidden-print">
        <v-card>
            <v-app-bar dark>
                <v-app-bar-nav-icon @click.stop="drawer = !drawer"></v-app-bar-nav-icon>
                <v-toolbar-title class="title">
                    J-tax automotive solutions
                </v-toolbar-title>
            </v-app-bar>
        </v-card>
        <v-navigation-drawer v-model="drawer" absolute clipped temporary>
            <v-list nav dense>
                <v-list-item>
                    <v-list-item-content>
                        <v-list-item-title>
                            <span class="navbar-title">
                                {{ this.name || 'J-tax automotive solutions' }}
                            </span>
                        </v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
                <v-list-item-group active-class="deep-purple--text text--accent-4">
                    <v-list-item @click="navigate('/taxaties/lijst')">
                        <v-list-item-icon>
                            <v-icon>mdi-speedometer</v-icon>
                        </v-list-item-icon>
                        <v-list-item-content>
                            <v-list-item-title>Taxaties</v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>
                    <v-list-item @click="navigate('/klanten')">
                        <v-list-item-icon>
                            <v-icon>mdi-account-multiple</v-icon>
                        </v-list-item-icon>
                        <v-list-item-content>
                            <v-list-item-title>Klanten</v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>
                    <v-list-item v-if="admin" @click="navigate('/statistieken')">
                        <v-list-item-icon>
                            <v-icon>mdi-chart-line</v-icon>
                        </v-list-item-icon>
                        <v-list-item-content>
                            <v-list-item-title>Statistieken</v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>
                    <v-list-item v-if="admin" @click="navigate('/admin')">
                        <v-list-item-icon>
                            <v-icon>mdi-cog</v-icon>
                        </v-list-item-icon>
                        <v-list-item-content>
                            <v-list-item-title>Admin</v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>
                    <v-list-item @click="logout()">
                        <v-list-item-icon>
                            <v-icon>mdi-logout</v-icon>
                        </v-list-item-icon>
                        <v-list-item-content>
                            <v-list-item-title>Uitloggen</v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>
                </v-list-item-group>
            </v-list>
        </v-navigation-drawer>
    </div>
</template>

<script>
import Store from '../../store';

export default {
    data() {
        return {
            drawer: false,
            admin: Store.get('admin'),
            name: Store.get('username')
        };
    },
    methods: {
        navigate(url) {
            this.$router.push(url);
        },
        logout() {
            localStorage.clear();
            this.$router.replace('/login');
            this.$emit('logout');
        }
    }
};
</script>

<style scoped>
.navbar-title {
    font-size: 1.5rem;
    font-weight: 600;
    text-transform: capitalize;
}

.v-list-item {
    padding: 0.2rem 8px !important;
    border-top: 1px #ccc !important;
}

.v-list-item > .v-list-item__content > .v-list-item__title {
    font-size: 0.9rem;
    margin-left: 0.5rem;
}
</style>
