export default class Store {
    static store = {
        admin: localStorage.getItem('admin') === 'true' || false,
        username: localStorage.getItem('username') || '',
        printTaxaties: localStorage.getItem('printTaxaties') || '[]'
    };

    static get(key) {
        return this.store[key];
    }

    static set(key, value) {
        localStorage.setItem(key, value);
        this.store[key] = value;
    }
}
