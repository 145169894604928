import Vue from 'vue';
import Meta from 'vue-meta';
import VueRouter from 'vue-router';
import Vuex from 'vuex';
import './App.css';
import App from './App.vue';
import vuetify from './plugins/vuetify';
import router from './router/index';

Vue.config.productionTip = false;

Vue.use(VueRouter);
Vue.use(Vuex);
Vue.use(Meta);

new Vue({
    vuetify,
    router,
    render: h => h(App)
}).$mount('#app');
